import React, { useState, useEffect, useRef, useCallback } from "react";
import Header from "./components/Header";
import termsData from "./ss_glossary.json";

export default function Glossary() {
  const [activeLetter, setActiveLetter] = useState("A");
  const [searchTerm, setSearchTerm] = useState("");
  const [originFilter, setOriginFilter] = useState(""); // Empty string means no filter
  const [categoryFilter, setCategoryFilter] = useState("All"); // Empty string means no filter
  const [showScrollButton, setShowScrollButton] = useState(false); // For the scroll-to-top button

  const sectionRefs = useRef(
    Object.fromEntries(
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => [letter, null])
    )
  );

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  // Extract unique categories dynamically
  const uniqueCategories = [
    "All",
    ...new Set(termsData.map((term) => term.category || "Uncategorised")),
  ];

  // Filter and group terms by their starting letter
  const filteredTerms = termsData.filter((term) => {
    const matchesSearch =
      !searchTerm ||
      term.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      term.definition.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (term.explanation &&
        term.explanation.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesOrigin =
      !originFilter ||
      term.origin?.toLowerCase() === originFilter.toLowerCase();

    const matchesCategory =
      categoryFilter === "All" ||
      (term.category || "Uncategorised") === categoryFilter;

    return matchesSearch && matchesOrigin && matchesCategory;
  });

  const groupedTerms = alphabet.reduce((acc, letter) => {
    acc[letter] = filteredTerms.filter((term) =>
      term.term.toUpperCase().startsWith(letter)
    );
    return acc;
  }, {});

  const handleScroll = useCallback(() => {
    let closestLetter = null;
    let closestDistance = Number.POSITIVE_INFINITY;

    for (const letter of alphabet) {
      const section = sectionRefs.current[letter];
      if (!section) continue; // Skip if no section
      const { top } = section.getBoundingClientRect();
      const distance = Math.abs(top);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestLetter = letter;
      }
    }

    if (closestLetter) {
      setActiveLetter(closestLetter);
    }
  }, [alphabet]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const toggleScrollButtonVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", toggleScrollButtonVisibility);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", toggleScrollButtonVisibility);
    };
  }, [handleScroll]);

  const handleLetterClick = (letter) => {
    setActiveLetter(letter);
    if (sectionRefs.current[letter]) {
      sectionRefs.current[letter].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      <div className="glossary-container">
        {/* A-Z Index */}
        <div className="az-index">
          {alphabet.map((letter) => (
            <div
              key={letter}
              onClick={() => handleLetterClick(letter)}
              className={activeLetter === letter ? "selected" : ""}
              role="button"
              tabIndex={0}
              aria-label={`Scroll to section ${letter}`}
            >
              {letter}
            </div>
          ))}
        </div>

        <div>
          {/* Filters */}
          <div className="filters">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="origin-filter">
              <label>
                <input
                  type="radio"
                  name="origin"
                  value=""
                  checked={originFilter === ""}
                  onChange={() => setOriginFilter("")}
                />
                All
              </label>
              <label>
                <input
                  type="radio"
                  name="origin"
                  value="japanese"
                  checked={originFilter === "japanese"}
                  onChange={() => setOriginFilter("japanese")}
                />
                Japanese
              </label>
              <label>
                <input
                  type="radio"
                  name="origin"
                  value="western"
                  checked={originFilter === "western"}
                  onChange={() => setOriginFilter("western")}
                />
                Western
              </label>
            </div>
            <div className="category-filter">
              {uniqueCategories.map((category) => (
                <label key={category}>
                  <input
                    type="radio"
                    name="category"
                    value={category}
                    checked={categoryFilter === category}
                    onChange={() => setCategoryFilter(category)}
                  />
                  {category}
                </label>
              ))}
            </div>
          </div>
          {/* Terms List */}
          <div className="terms-list">
            {alphabet.map(
              (letter) =>
                groupedTerms[letter].length > 0 && (
                  <div
                    key={letter}
                    ref={(el) => (sectionRefs.current[letter] = el)}
                  >
                    <h2 id={letter}>{letter}</h2>
                    <ul>
                      {groupedTerms[letter].map((term, index) => (
                        <li
                          key={index}
                          className="glossary-term"
                          id={term.term}
                        >
                          <div className="term">{term.term}</div>
                          <div>
                            <div className="definition">{term.definition}</div>
                            {term.explanation && (
                              <div className="explanation">
                                {term.explanation}
                              </div>
                            )}
                            {term.see_also?.length > 0 && (
                              <div className="see-also">
                                {" "}
                                See also:{" "}
                                {term.see_also.map((seeAlsoTerm, index) => (
                                  <span key={index}>
                                    <a href={`#` + seeAlsoTerm}>
                                      {" "}
                                      {seeAlsoTerm}
                                    </a>
                                    {index < term.see_also.length - 1
                                      ? ", "
                                      : ""}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
            )}
          </div>
        </div>
        {showScrollButton && (
          <button
            className="scroll-to-top"
            onClick={handleScrollToTop}
            aria-label="Scroll to top"
          >
            ↑ Back to Top
          </button>
        )}
      </div>
    </>
  );
}
